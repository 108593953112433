import { render, staticRenderFns } from "./home.vue?vue&type=template&id=6cfe0fc1&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfe0fc1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TemplatesSectionsSpotlight: require('/home/ticketshoplv/public_html/ticketshop.lt/components/templates/sections/Spotlight.vue').default,EventGrid: require('/home/ticketshoplv/public_html/ticketshop.lt/components/Event/Grid.vue').default})
